<template>
  <div>
    <v-card flat>
      <v-card-text class="px-0">
        {{ $t("Tell a friend intro") }}
      </v-card-text>
    </v-card>
    <v-card flat max-width="1000">
      <v-card-text class="px-0">
        <v-form ref="formTellAFriend" v-model="valid" lazy-validation>
          <v-row dense>
            <v-col cols="12" class="pl-0">
              <h3 class="font-weight-bold black--text mb-0">
                {{ $t("Who can we contact?") }}
              </h3>
            </v-col>
            <v-col cols="12" sm="4" class="pl-0">
              <v-text-field
                v-model="tellAFriend.firstname"
                hide-details="auto"
                :label="$t('First name')"
                required
                :rules="getRules('notEmptyStringRule')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="tellAFriend.lastname"
                hide-details="auto"
                :label="$t('Last name')"
                required
                :rules="getRules('notEmptyStringRule')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8" class="pl-0">
              <v-text-field
                v-model="tellAFriend.email"
                hide-details="auto"
                prepend-inner-icon="mdi-email"
                :label="$t('Email')"
                required
                :rules="getRules('notEmptyEmailRule')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="tellAFriend.tel"
                hide-details="auto"
                prepend-inner-icon="mdi-phone"
                :label="$t('Phone')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" class="pl-0 mt-5">
              <h3 class="font-weight-bold black--text mt-5 mb-0">
                {{ $t("What services is this person interested in?") }}
              </h3>
              <v-radio-group
                required
                :rules="[
                  this.selected.length > 0 ||
                    $t('At least one item should be selected'),
                ]"
                class="mt-0"
                hide-details="auto"
              >
                <v-checkbox
                  :label="service"
                  v-model="selected"
                  hide-details="auto"
                  :value="service"
                  :class="index != 0 ? 'mt-3 pl-0' : 'pl-0'"
                  v-for="(service, index) in services[currentLanguage]"
                  :key="index"
                ></v-checkbox>
                <v-checkbox
                  v-model="selected"
                  class="mt-3 pl-0"
                  :value="$t('Remaining')"
                  :label="$t('Remaining')"
                  hide-details="auto"
                ></v-checkbox>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-if="selected.includes($t('Remaining'))"
                v-model="tellAFriend.serviceText"
                required
                :rules="getRules('notEmptyStringMinLengthRule')"
                background-color="grey lighten-5"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-layout class="mt-auto">
      <v-spacer></v-spacer>
      <v-btn class="mr-4" text @click="tellAFriendDialog = false">
        {{ $t("Cancel") }}
      </v-btn>

      <v-btn
        color="primary"
        :loading="loadingTellAFriend"
        @click="sendTellAFriend()"
      >
        {{ $t("Send") }}
      </v-btn>
    </v-layout>
    <errorHandeling
      v-if="success"
      :snackbarText="success"
      snackbarColor="success"
      snackbarTimout="-1"
      snackbarIcon="mdi-check-circle"
      @clearError="$store.dispatch('resetError')"
    />
    <errorHandeling
      v-if="error"
      :snackbarText="error"
      snackbarColor="error"
      snackbarTimout="-1"
      snackbarIcon="mdi-alert-circle"
      @clearError="$store.dispatch('resetError')"
    />
  </div>
</template>

<script>
import validation from "../../validation";
import request from "../../request";
export default {
  data() {
    return {
      tellAFriend: {
        email: "",
        firstname: "",
        lastname: "",
        tel: "",
        serviceText: "",
      },
      services: {
        Nl: ["Website", "Online portaal", "Advies"],
        Fr: ["Site Internet", "Portail en ligne", "Conseils"],
        En: ["Website", "Online Portal", "Advice"],
      },
      valid: true,
      selected: [],
      success: null,
      error: null,
      loadingTellAFriend: false,
    };
  },
  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
  },
  methods: {
    getRules(name) {
      return validation.getValidation(name);
    },
    sendTellAFriend() {
      if (this.$refs.formTellAFriend.validate()) {
        this.loadingTellAFriend = true;
        request.post(
          "/api/credits-tell-a-friend",
          {
            FirstName: this.tellAFriend.firstname,
            LastName: this.tellAFriend.lastname,
            Email: this.tellAFriend.email,
            Phone: this.tellAFriend.tel,
            Services: this.selected,
          },
          (res) => {
            this.loadingTellAFriend = false;
            if (res.success) {
              this.success = this.$t(
                "Thanks for the information! We will keep you informed."
              );
              this.$refs.formTellAFriend.reset();
            } else {
              this.error =
                res.message ||
                this.$t("Oops! Something went wrong, please try again.");
            }
          }
        );
      }
    },
  },
};
</script>

<style>
</style>